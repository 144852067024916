<template>
    <div class="map-container mb-8 border shadow-md">
        <iframe allowfullscreen=""
                class="w-full h-full"
                loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2160.7011948300337!2d29.043468085978418!3d37.843077920359214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b92b1392c308bd%3A0x425c0a3702fb092!2sHidrobarsan%20Mermer%20Makinalar%C4%B1%20San%20ve%20Tic.%20Ltd.%20%C5%9Eti.!5e0!3m2!1str!2str!4v1682357605722!5m2!1str!2str"
                style="border:0;"
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "Map"
}
</script>

<style lang="scss" scoped>

.map-container {
  height: 200px;
}

@media (min-width: 768px) {

  .map-container {
    height: 500px;
  }

}

</style>
