<template>
    <div class="px-6 h-44 mt-16 mb-16" v-if="false">
        <iframe
                height="100%"
                src=""
                title="Product Video"
                width="100%"
        />
    </div>
</template>

<script>
export default {
    name: "HomepageVideo"
}
</script>

<style scoped>

</style>
